<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_merge_section')" :isColumns="true" :isFilter="false"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>

            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('course_merge_section')" :isColumns="true" :isFilter="false"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <b-card :header="$t('search_section')" class="text-start">
                <b-card-body class="p-0">
                    <datatable-filter v-show="datatable.filterStatus" :exportExcel="true" @filter="filter"
                        @filterClear="filterClear">
                        <b-row>

                            <b-col sm="6" md="4" lg="3">
                                <b-form-group :label="$t('semester')">
                                    <semesters-selectbox v-model="datatable.queryParams.filter.semester_id">
                                    </semesters-selectbox>
                                </b-form-group>
                            </b-col>

                            <b-col sm="4">
                                <b-form-group :label="$t('course')">
                                    <course-auto-complete v-model="datatable.queryParams.filter.course_id" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </datatable-filter>
                    <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                        :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange" :title="$t('joining_place')" v-show="datatable.showTable">
                    </datatable>
                </b-card-body>
            </b-card>

            <b-card :header="$t('merged_sections')" class="text-start mt-5">
                <b-card-body class="p-0">
                    <datatable-filter class="mt-4" v-show="true" @filter="filterMerge" @filterClear="filterMergeClear">
                        <b-row>
                            <b-col sm="6" md="4" lg="3">
                                <b-form-group :label="$t('section_id')">
                                    <b-form-input v-model="datatableMerge.queryParams.filter.section_ids" />
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="4" lg="3">
                                <b-form-group :label="$t('code')">
                                    <b-form-input v-model="datatableMerge.queryParams.filter.code" />
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="4" lg="3">
                                <b-form-group :label="$t('name')">
                                    <b-form-input v-model="datatableMerge.queryParams.filter.name" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </datatable-filter>

                    <datatable class="my-3" :isLoading.sync="datatableMerge.isLoading" :columns="datatableMerge.columns"
                        :rows="datatableMergeRowsComp" :total="datatableMerge.total"
                        :queryParams="datatableMerge.queryParams" @on-page-change="onPageChangeMerge"
                        @on-sort-change="onSortChangeMerge" @on-per-page-change="onPerPageChangeMerge"
                        :title="$t('place_added')" v-show="datatableMerge.showTable">
                    </datatable>
                    <b-col cols="6" md="12" class="my-2 p-0">
                        <b-button variant="primary" @click="saveCollection">
                            {{ $t('save') }}
                        </b-button>
                    </b-col>
                </b-card-body>
            </b-card>


            <CommonModal ref="createFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('add').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form v-if="formProcess == 'create'"
                        :addValueObject="{ section_ids: JSON.stringify(Ids) }"
                        @createFormSuccess="createFormSuccess" />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId" @updateFormSuccess="updateFormSuccess" v-if="formProcess == 'update'" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";

// Services
import CourseMergeSection from "@/services/CourseMergeSections";
import SectionService from "@/services/SectionService"

import qs from 'qs'

// Page
import UpdateForm from "./UpdateForm";
import CreateForm from "./CreateForm";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";

export default {
    name: "courseMergeSections",
    components: {
        CourseAutoComplete,
        HeaderMobile,
        Header,
        AppLayout,
        DatatableFilter,
        Datatable,
        CommonModal,
        UpdateForm,
        CreateForm
    },
    metaInfo() {
        return {
            title: this.$t("course_merge_section")
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('add'),
                                class: 'ri-add-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.addSection(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('course_code'),
                        field: 'course_code',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('course_name'),
                        field: 'course_name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('section'),
                        field: 'section',
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: true,
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 20
                }
            },
            datatableMerge: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "mergesection_delete",
                                callback: (row) => {
                                    this.formDelete(row.code, row.id)
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'mergesection_update',
                                callback: (row) => {
                                    this.loadData(row.id);
                                },
                                show: (row) => {
                                    return this.extractCollection(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('course_code'),
                        field: 'code',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('course_name'),
                        field: 'name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('teams'),
                        field: 'for_teams',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('lms'),
                        field: 'for_lms',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('section_id'),
                        field: 'section_ids',
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                showTable: true,
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 20
                }
            },
            tSections: [],
            collection: [],
            Ids: [],
            formProcess: null,
            formId: null,
            addSectionClick: false,
            lastMergeId: null
        }
    },
    computed: {
        datatableMergeRowsComp() {
            if (this.lastMergeId) {
                return this.datatableMerge.rows.filter(i => i.id === this.lastMergeId)
            }
            return this.datatableMerge.rows
        }
    },
    methods: {
        createFormShow() {
            this.formId = null
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        filterSet() {
            this.datatable.queryParams.filter = {
                course_code: null,
                course_name: null,
                department_code: null,
            }
            this.getRows()
        },
        filterMergeSet() {
            this.datatableMerge.queryParams.filter = {}
            this.getRowsMergeCourses();
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filterMergeClear() {
            this.filterMergeSet()
            this.getRowsMergeCourses();
        },
        filter() {
            this.datatable.queryParams.page = 1
            this.getRows();
        },
        filterMerge() {
            this.lastMergeId = null
            this.addSectionClick = false
            this.datatableMerge.queryParams.page = 1
            this.getRowsMergeCourses();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChangeMerge(params) {
            this.datatableMerge.queryParams.page = params.currentPage;
            this.getRowsMergeCourses();
        },
        onPerPageChangeMerge(params) {
            this.datatableMerge.queryParams.limit = params.currentPerPage;
            this.datatableMerge.queryParams.page = 1;
            this.getRowsMergeCourses();
        },
        onSortChangeMerge(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatableMerge.queryParams.sort = sortType + params[0].field
            this.getRowsMergeCourses();
        },
        addSection(row) {
            if (!this.checkPermission('mergesection_store')) return

            this.datatableMerge.isLoading = true;
            this.lastMergeId = null
            if (!this.addSectionClick) {
                this.datatableMerge.rows = []
                this.addSectionClick = !this.addSectionClick
            }

            if (this.checkSameSectionId(row.id)) {
                this.$toast.error(this.$t(this.$t('already_available')));
                this.datatableMerge.isLoading = false;
                return
            }
            this.addCollection(row)
            this.datatableMerge.isLoading = false;
            this.$toast.success(this.$t('added'))
        },

        checkSameSectionId(id) {
            return this.collection.some(section => section.id === id)
        },
        checkCollectionLength(data = []) {
            if (data.length > 99999) return false
            return true
        },
        addCollection(section) {
            if (!this.checkCollectionLength(this.collection)) {
                this.$toast.error(this.$t('you_can_combine_up_to_five_lessons'))
                return
            }
            let data = {
                code: section.course_code,
                name: section["course_name"],
                for_teams: this.teams,
                for_lms: this.lms,
                class_id: section.class_id,
                semester_id: section.semester_id,
                section_ids: section.section,
                id: section.id
            }
            this.collection.push(data);
            this.datatableMerge.rows.push(data);
        },
        getRowsMergeCourses() {
            this.datatableMerge.showTable = true;
            this.datatableMerge.isLoading = true;

            const config = {
                params: {
                    ...this.datatableMerge.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return CourseMergeSection.get(config)
                .then((response) => {
                    let data = response.data.data
                    let configData = data.map(course => {
                        return { ...course, section_ids: JSON.parse(course.section_ids).join(",") }
                    })
                    this.datatableMerge.rows = [...configData, ...this.collection]
                    this.datatableMerge.total = response.data.pagination.total
                }).catch((err) => this.showErrors(err))
                .finally(() => {
                    this.datatableMerge.isLoading = false;
                });
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatableMerge.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return SectionService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                }).catch((err) => this.showErrors(err))
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        saveCollection() {
            if (this.collection == null || this.collection.length == 0) {
                this.$toast.error(this.$t("no_lessons_to_be_added"));
                return false
            }
            if (this.formProcess !== 'create') this.collectIds();
            this.formProcess = 'create';
            this.$refs.createFormModal.$refs.commonModal.show()
        },

        collectIds() {
            this.collection.forEach(section => {
                this.Ids = this.Ids.concat([section.id])
            })
        },
        collectionDelete(course_code, id) {
            let firstLength = this.collection.length;
            if (firstLength === 0) return false
            this.collection = this.collection.filter(section => section.id !== id);

            let check = firstLength > this.collection.length;
            if (check) {
                this.datatableMerge.rows = this.collection
                this.$toast.success(this.$t("merge_section_deleted"));
                return check
            }
        },
        formDelete(course_code, id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.collectionDelete(course_code, id)) {

                            if (!this.addSectionClick) {
                                this.getRowsMergeCourses();
                            }
                            return
                        }
                        CourseMergeSection.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRowsMergeCourses();
                            })
                            .catch(error => {
                                this.showErrors(error)
                            });
                    }
                })
        },
        loadData(id) {
            this.formProcess = "update";
            this.formId = id;
            this.$refs.updateFormModal.$refs.commonModal.show();
        },
        formClear() {
            this.formId = null;
            this.formProcess = null;
            this.Ids = [];
        },
        showForm(id) {
            this.formId = id
            this.formProcess = 'show'
            this.$refs.showFormModal.$refs.commonModal.show()
        },
        createFormSuccess(id) {
            this.lastMergeId = id
            this.addSectionClick = false
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRowsMergeCourses()
            this.formClear();
            this.collection = [];
            this.Ids = [];
        },
        updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRowsMergeCourses()
            this.formClear();
        },
        extractCollection(row) {
            return !this.collection.some(x => x.id == row.id)
        },
        getSections() {
            let config = {
                params: { limit: -1 },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }
            return SectionService.getAll(config).then(response => {
                this.tSections = response.data.data.filter(function (item) {
                    return item.type == 1;
                }).map(function (item) {
                    return { value: item.id, text: item.section };
                });

                this.pSections = response.data.data.filter(function (item) {
                    return item.type == 2;
                }).map(function (item) {
                    return { value: item.id, text: item.section };
                });
            });
        }
    },
    created() {
        this.getSections()
    }
}
</script>
