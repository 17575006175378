<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('name')">
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-input-group class="mb-3">
                            <b-form-input v-model="form.name" :class="errors[0] ? 'is-invalid' : ''">
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-form-group>
                <div class="col-12">
                    <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('code')">
                            <b-form-input v-model="form.code">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox :validate-error="errors[0]" v-model="form.semester_id"
                                :setActive="true"></semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <b-col sm="6">
                    <b-form-checkbox id="teams" v-model="form.for_teams" name="checkbox-1" :value="1" :unchecked-value="0">
                        Teams
                    </b-form-checkbox>
                </b-col>

                <b-col sm="6">
                    <b-form-checkbox id="lms" v-model="form.for_lms" name="checkbox-1" :value="1" :unchecked-value="0">
                        Lms
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex">
            <b-button @click="createForm" type="button" variant="primary" class="btn-lg mr-2" :disabled="formLoading">
                {{ $t('save').toLocaleUpperCase('tr-TR') }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Other
import { ValidationProvider, ValidationObserver } from "vee-validate"
//Service
import CourseMergeSectionsService from "@/services/CourseMergeSections";
//Component
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

export default {
    components: {
        ValidationProvider, ValidationObserver,

        SemestersSelectbox,
    },
    props: {
        addValueObject: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            formLoading: false,
            form: {}
        }
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate()
            if (isValid) {
                this.form = { ...this.addValueObject, ...this.form }
                this.formLoading = true;
                CourseMergeSectionsService.store(this.form)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("createFormSuccess", response.data.data.id)
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate)
                    }).finally(() => {
                        this.formLoading = false;
                    });
            }
        }
    }
}
</script>
